import { graphql, Link, PageProps } from "gatsby";
import * as React from "react";
import { TimelinePageQuery } from "../../generated/graphql";
import Shell from "../../layout/shell";

import greenArrow from "../../assets/images/icon-rightarrow-green.svg";

import * as styles from "./timeline.module.scss";
import { VerticalWrapPanel } from "../../components/verticalWrapPanel";
import { GatsbyImage } from "gatsby-plugin-image";

const Timeline: React.FC<PageProps<TimelinePageQuery>> = (pageProps) => {
  const data = pageProps.data.prismicTimelinePage?.data;
  if (!data) {
    return null;
  }
  
  React.useEffect(() => {
    const element = document.querySelector("a[aria-current='page']");
    if (element){
      element.scrollIntoView(false);
    }
  }, []);
  
  return (
    <Shell rootActive={true}>
      <div className={styles.timelinePage}>
        <div className="fixed-width">
          <p className="intro">{data.intro_text}</p>
          <hr />
          {data.timeline?.map((tlItem, index) => (
            <div className={styles.timelineItem}>
              <div className={styles.timelineNumber}>
                <span>0{index + 1}</span>
                <img src={greenArrow} />
              </div>
              <div>
                <h3>{tlItem?.timeline_item_title}</h3>
                <p>{tlItem?.timeline_item_description}</p>
                {tlItem?.timeline_link && <Link className="button" to={tlItem?.timeline_link}>For more information</Link>}
              </div>
            </div>
          ))}
          <br/>
          <br/>
          <hr />
          <br/>
          <div className="list-block" data-lists={data.body.length}>
            {data.body.map((list, index) => (
              <div>
                <h3>{list.primary.title}</h3>
                <VerticalWrapPanel
                  maxRows={index === 0 ? 5 : 4}
                  verticalGap={20}
                  packing="tight"
                  horizontalGap={100}
                >
                  {list.items.map((li) => (
                    <div className="compact-list-item">
                    {li.list_item_image && <GatsbyImage alt={li.list_item_image.alt || 'List item icon'} image={li.list_item_image.gatsbyImageData}/>}
                    <div>
                      <strong>{li.list_item_title}</strong>
                      {li.list_item_description && (
                        <div>{li.list_item_description}</div>
                      )}

                    </div>
                    </div>
                  ))}
                </VerticalWrapPanel>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query TimelinePage {
    prismicTimelinePage {
      data {
        intro_text
        timeline {
          timeline_item_title
          timeline_item_description
          timeline_link
        }
        body {
          __typename
          ... on PrismicTimelinePageDataBodyBlockList {
            items {
              list_item_description
              list_item_title
              list_item_image {
                alt
                gatsbyImageData
                url
              }
            }
            primary {
              title
            }
          }
        }
      }
    }
  }
`;

export default Timeline;
